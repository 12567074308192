
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.single__visual {
  object-fit: cover;
}
