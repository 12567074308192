
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































.clip {
  --clip: 99%;

  clip-path: inset(-1% var(--clip) -1% -1%);
}

video {
  width: 100%;
}

.video-poster-container {
  position: relative;

  img {
    width: 100%;
    transform: scale(var(--scale)) translateY(var(--translate));
  }
}

.video-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $c-white;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;

  // prettier-ignore
  @include fluid(font-size, (xxs: 20px, xl: 40px));
}

.video-play__svg {
  @include fluid(
    width,
    (
      xxs: 40px,
      xl: 72px,
    )
  );
  @include fluid(
    height,
    (
      xxs: 40px,
      xl: 72px,
    )
  );

  margin-bottom: 1.4rem;
}
