
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































::v-deep {
  .alt-heading {
    width: 50%;
  }

  .solution-title__index,
  .solution-title::after {
    display: none;
  }

  .solution-title__icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.1rem;
    fill: $c-main;
  }

  .solution-title__title {
    font-family: $ff-alt;
    font-size: 2rem;
    line-height: 1;
    text-transform: uppercase;
    width: 100%;
  }
}

.products-solutions {
  margin-bottom: 10rem;
}

.solution {
  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  &__link {
    display: flex;
    align-items: center;
  }
}

.product {
  font-size: 1.8rem;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }
}
