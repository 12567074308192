
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































svg {
  stroke: currentColor;
}
