
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































::v-deep {
  // TextSplitter will inline text-align at initialization but it won't be updated
  // when resize
  .big-cta__label {
    [style*='text-align: right'] {
      @include mq($until: l) {
        text-align: start !important;
      }
    }

    [style*='text-align: start'] {
      @include mq($until: l) {
        text-align: right !important;
      }
    }
  }
}

.big-cta {
  position: relative;
  display: block;
  color: $c-black;
  background-color: $c-main;

  &__label {
    margin-bottom: 0.5rem;

    @include mq(l) {
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: -1.5rem;
    font-size: clamp(3.6rem, 4.76vw, 8rem);
    font-weight: 600;
    line-height: 1;
  }

  &__icon {
    display: none;
    fill: currentColor;
    width: 8rem;

    @include mq(xs) {
      display: block;
      margin-left: 2rem;
    }
  }

  &:hover,
  &:focus {
    .big-cta__inner--active {
      clip-path: inset(
        -1% -1% -1% -1%
      ); // -1% to prevent horizontal line of pixels in some cases
    }
  }
}

.big-cta__inner,
[class*='big-cta__inner--'] {
  &[class*='--active'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $c-text-light;
    background: $c-surface-dark;
    clip-path: inset(
      -1% 100% -1% 0
    ); // -1% to prevent horizontal line of pixels in some cases
    transition: clip-path $transition-duration * 2 $easing;
  }
}
